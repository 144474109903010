<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-archive"></i>
            ارشيف الرسائل
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-12 col-lg-6">
              <label for="">بحث في الأرشيف</label>
              <input
                type="text"
                class="form-control"
                v-model="q"
                placeholder="ابحث هنا برقم الهاتف او بمحتوى الرسالة او بالتاريخ..."
              />
            </div>
            <div class="form-group col-12 col-lg-4">
              <br />
              <button class="btn btn-primary btn-sm" @click="getMessages()">
                <i class="fa fa-search"></i>
                بحث
              </button>
              &nbsp;&nbsp;&nbsp;
              <button class="btn btn-danger btn-sm" @click="moveto2()">
                <i class="fa fa-arrow-left"></i>
                نقل الارشيف
              </button>
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table class="table table-hover table-striped">
              <thead>
                <th>الهاتف</th>
                <th>محتوى الرسالة</th>
                <th>التاريخ</th>
                <th>الحالة</th>
                <th>ارسلت من خلال</th>
              </thead>
              <tbody>
                <tr v-for="message in messages" :key="message._id">
                  <td>{{ message.to }}
                    <a v-if="users[message.user_id].name == 'salah'" :href="`https://api.whatsapp.com/send?phone=${message.to}&text=${encodeURI(message.message)}`" target="_blank" class="text-danger"><small>اعادة ارسال</small></a>
                  </td>
                  <td>
                    <textarea
                      style="width: 100%; border: none; min-width: 200px; min-height: 30px"
                      rows="3"
                      v-model="message.message"
                    ></textarea>
                    <span v-if="message.file != ''">
                      <a :href="message.file" target="_blank">
                        <i class="fa fa-file"></i>
                        عرض المرفق
                      </a>
                    </span>
                  </td>
                  <td>{{ message.date }}</td>
                  <td>
                    <span class="badge bg-info" v-if="message.status == 0">
                      <div class="spinner-border" role="status"></div>
                      في انتظار الارسال
                    </span>
                    <span class="badge bg-success" v-if="message.status == 1">
                      <i class="fa fa-check"></i>
                      تم الارسال
                    </span>
                    <span class="badge bg-warning" v-if="message.status == 3">
                      <i class="fa fa-clock-o"></i>
                      ايقاف مؤقت
                    </span>
                    <span class="badge" style='background: #B931FC; color: white' v-if="message.status == 5">
                      مجدولة
                    </span>
                    <span v-if="message.status == 5">{{ message.schedule }}</span>
                    <span class="badge bg-danger" v-if="message.status == 2">
                      <i class="fa fa-times"></i>
                      {{ message.response }}
                    </span>
                    <span v-if="message.sms">
                      <span v-if="message.sms.status != -1" style="cursor:pointer;" @click="smsInfo(message.sms)">
                        <span class="badge bg-info" v-if="message.sms.status == 0">
                      SMS:
                      <div class="spinner-border" role="status"></div>
                      في انتظار الارسال
                    </span>
                    <span class="badge bg-success" v-if="message.sms.status == 1">
                      SMS:
                      <i class="fa fa-check"></i>
                      تم الارسال
                    </span>
                    <span class="badge bg-warning" v-if="message.sms.status == 3">
                      SMS:
                      <i class="fa fa-clock-o"></i>
                      ايقاف مؤقت
                    </span>
                    <span class="badge bg-danger" v-if="message.sms.status == 2">
                      SMS:
                      <i class="fa fa-times"></i>
                      اضغط للتفاصيل
                    </span>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span v-if="message.sent_from == 'exten'">من الاداة</span>
                    <span
                      v-if="
                        message.sent_from != '' &&
                        message.sent_from != 'exten' &&
                        devices[message.sent_from]
                      "
                    >
                      {{ devices[message.sent_from].title }}
                    </span>
                    <small v-if="users[message.user_id]">
                      <br />
                      <i>
                        <i class="fa fa-user"></i>
                        {{ users[message.user_id].name }}</i
                      >
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-secondary"
            @click="
              page = page - 1;
              getMessages();
            "
            v-if="page > 0"
          >
            <i class="fa fa-arrow-right"></i>
            السابق
          </button>
          <button class="btn btn-default">
            {{ page + 1 }}
          </button>
          <button
            class="btn btn-primary"
            @click="
              page = page + 1;
              getMessages();
            "
            v-if="messages.length > 0"
          >
            التالي
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: true,
      messages: [],
      devices: {},
      users: {},
      q: null,
      page: 0,
      inertval: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/admin/devices/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        r.response.forEach((element) => {
          g.devices[element._id] = element;
        });
        $.post(api + "/admin/users/list", {
          jwt: g.user.jwt,
        })
          .then(function (r) {
            r.response.forEach((element) => {
              g.users[element._id] = element;
            });
            g.getMessages();
            g.inertval = setInterval(() => {
              g.getMessages(true);
            }, 5000);
          })
          .catch(function () {
            g.loading = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
  beforeDestroy() {
    if (this.inertval) {
      clearInterval(this.inertval);
    }
  },
  methods: {
    getMessages(noloading = false) {
      var g = this;
      if (!noloading) {
        g.loading = true;
      }
      $.post(api + "/admin/messages/archive", {
        jwt: this.user.jwt,
        q: g.q,
        page: g.page,
        user_id: window.location.hash
          ? window.location.hash.split("#")[1]
          : undefined,
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.messages = r.response;
          } else {
            alert("حدث خطأ2");
          }
        })
        .catch(function () {
          g.loading = false;
          alert("حدث خطأ");
        });
    },
    
    smsInfo(info){
      alert(`portal:        ${info?.portal}\nusername: ${info?.username}\npassword: ${info?.password}\nsender:      ${info?.sendername}\nالرد:   ${info.response}`);
    },
    moveto2(){
      var g = this;
      var date = new Date();
      date.setDate(date.getDate() - 3);
      var date = prompt("نقل الرسائل التي قبل تاريخ:", date.toISOString().split("T")[0])
      if(date && date !=""){
        var pass = prompt("لتأكيد نقل الارشيف اكتب 'brmja'", "")
        if(pass == "brmja"){
          $.post(api + "/admin/messages/move-to-2", {
            jwt: g.user.jwt,
            date: date
          })
            .then(function (r) {
              g.loading = false;
              if (r.status == 100) {
                alert("جاري الان نقل الارشيف... ربما تستغرق دقيقتين.")
              } else {
                alert("حدث خطأ2");
              }
            })
            .catch(function () {
              g.loading = false;
              alert("حدث خطأ");
            });
        }
      }
    }
  },
};
</script>

<style>
</style>